<template>
  <section class="m-goods m-scroll"  @scroll="handleScroll($event)" ref="wrapper">

    <!-- 视频 & 轮播图 -->
    <goods-swipe ref="banner" :images="goodsInfo.round_pic_url" :videoData="goodsInfo.video_url == '' ? {} : goodsInfo.video_url"
    :hasVideo="goodsInfo.video_url != null && goodsInfo.video_url != ''" :goodsInfo="goodsInfo" />
    <!-- 头部导航 -->
    <div class="head-bar m-padding" ref="bar">
      <img @click="goodsBack" src="@/assets/goods/icon_back_2@2x.png" alt="img">
      <div>
        <router-link to="/en/cart">
          <img src="@/assets/goods/cart.png" alt="img" :class="{'cart':cartAnimate}">
          <m-dot :animate="dotAnimate" ref="dot"></m-dot>
        </router-link>
        <router-link to="/en">
          <img src="@/assets/goods/icon_home_3@2x.png" alt="">
        </router-link>
      </div>
    </div>

    <!-- 标题 分享 -->
    <div class="price-share m-padding">
      <div class="title van-multi-ellipsis--l2">
        <img src="@/assets/goods/pre_new_en.png" alt="" v-if="isNew">
        {{goodsInfo.goods_info.name}}
      </div>
      <div class="share" @click="shareHandle" v-if="$route.params.id != '3751'">
        <img src="@/assets/goods/icon_fenxiang@2x.png" alt="img">
      </div>
    </div>

    <!-- 价格 评分 -->
    <div class="price-share m-padding">
      <div class="price">
        <div>
          <p><span>$</span><span>{{(goodsPrice - 0).toFixed(2)}}</span></p>
          <p v-if="(goodsInfo.activity_min_price *1).toFixed(0) != 0 ? true : goodsInfo.off != 0">${{(goodsCostPrice - 0).toFixed(2)}}</p>
        </div>
        <div class="agent" v-if="goodsInfo.agent">Partner agency price： <span>${{proxyPrice}}</span></div>
      </div>
      <div class="share">
        <van-rate v-model="commentTotal.all" color="#F03C18" :size="15" allow-half void-color="#fff " readonly />
      </div>
    </div>

    <!-- 商品服务；选择SKU -->
    <div class="sku-select m-padding">
      <div class="sku-cell">
        <div class="sku-box">
          <div class="sku-value" v-for="(it, index) in skuData.attr" :key="index">
            <p class="title">{{it.title_en}}</p>
            <div class="values">
              <template v-for="(i,ind) in it.val">
                <span :key="ind" :class="{'active':i.active, 'emptyStock': i.emptyStock, 'hidden': i.hidden}"
                @click="handleActive(it, i, index)" v-if="!i.hidden || (index == 0 && skuData.attr.length > 1)">
                  {{i.attr_title_en}}
                </span>
              </template>
            </div>
          </div>

          <!-- 预售弹窗 -->
          <div class="pre-box" v-if="isEmpty">
            <div class="tips">
              <p>Delivery instructions:</p>
              <p>
                  Estimated time of arrival at warehouse in {{batch.purchase_period + batch.order_period + batch.delivery_date}}  business days. 
              </p>
            </div>
          </div>
          
          <div class="sku-num">
            <p class="title">Number</p>
            <div class="number">
              <img @click="OnReduce" src="@/assets/components/icon_jian@2x.png" alt="reduce">
              <input v-model="number" type="number" readonly class="van-hairline--top van-hairline--bottom" style="font-size: 4vw;">
              <img @click="onAdd" src="@/assets/components/icon_jia@2x.png" alt="add">
              <span class="detail-f"
              v-if="isEmpty && number == 5">
                Maximum 5 items
              </span>
              <span class="detail-f"
              v-if="!isEmpty && skuStock.sku_stock && number == skuStock.sku_stock.stock">
                No more stocks
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="servies-cell">
        <p>Service</p>
        <p v-if="goodsInfo.after_sale == 1">7 days hassle-free return</p>
        <p v-if="goodsInfo.after_sale == 2">Refund apply to quality problem only</p>
        <p v-if="goodsInfo.after_sale == 3">This product has not apply to any term on return policy</p>
      </div>
    </div>

    <!-- 商品评论 -->
    <div class="comment-title m-padding van-hairline--bottom">
      <span>Reviews</span>
      <div>
        <router-link :to="`/en/goods/comment/${$route.params.id}`">{{commentTotal.count}} reviews</router-link>
        <img  src="@/assets/order/icon-fanhui@2x.png" alt="img">
      </div>
    </div>
    <!-- <div class="comment-content">
      <div class="comment-empty">暂无评论</div>
    </div> -->
    <div class="comment-content">
      <!-- <comment-list :data="1"></comment-list> -->
      <div class="en-goods-comment m-padding">
        <div class="comment-data-box">
          <div class="data-points"><p>{{commentTotal.all}}</p><p>out of 5</p></div>
          <div class="comment-data">
            <p>
              <span>Excellent</span>
              <span><span :style="{width: `${commentTotal.c5 / commentTotal.count * 100}%`}"></span></span>
            </p>
            <p>
              <span>Very Good</span>
              <span><span :style="{width: `${commentTotal.c4 / commentTotal.count * 100}%`}"></span></span>
            </p>
            <p>
              <span>Average</span>
              <span><span :style="{width: `${commentTotal.c3 / commentTotal.count * 100}%`}"></span></span>
            </p>
            <p>
              <span>Bad Post</span>
              <span><span :style="{width: `${commentTotal.c12 / commentTotal.count * 100}%`}"></span></span>
            </p>
          </div>
        </div>
        <van-rate v-model="commentTotal.all" color="#F03C18" :size="15" allow-half void-color="#bbb " readonly class="mar-tb" />
        <p class="link">
          <router-link :to="`/en/goods/comment/${$route.params.id}`">View all</router-link>
          <!-- <router-link to="">Buying advice</router-link> -->
        </p>
      </div>
    </div>

    <!-- 为您推荐 热销产品 -->
    <p class="hot-title">Similar ltems</p>
    <index-new style="padding-top:0" :data="remGoodsList" :loading="loading"></index-new>

    <!-- 回到顶部 -->
    <m-scroll-top :show="scrollTop" :dom="$refs.wrapper" ref="scrollTop"></m-scroll-top>
    
    <!-- 底部控制栏 -->
    <div class="foot-bar">
      <router-link to="/en/presale?id=6" v-if="goodsInfo.merchant_id == 1 && goodsInfo.pre_info.is_pre == 1">
        <img src="@/assets/goods/pre.png" alt="store">
      </router-link>
      <router-link to="/en/mooby?id=4" v-if="goodsInfo.merchant_id == 1 && goodsInfo.pre_info.is_pre == 0">
        <img src="@/assets/goods/icon_dianpu@2x.png" alt="servies">
      </router-link>
      <router-link :to="`/en/merchant/index/${goodsInfo.merchant_id}`" v-if="goodsInfo.merchant_id != 1">
        <img src="@/assets/goods/icon_dianpu@2x.png" alt="servies">
      </router-link>

      <router-link to="/en/customer">
        <img src="@/assets/goods/icon_kefu@2x.png" alt="servies">
      </router-link>
      <div class="favorite" @click="favoriteGoodsHandle">
        <img src="@/assets/goods/icon_shouchang@2x.png" alt="img" v-if="goodsInfo.goods_collect == null">
        <img src="@/assets/goods/icon_shouchang@2x_a.png" alt="img" v-else>
      </div>
      <div class="btns" v-if="this.$route.params.id != '3751'">
        <span @click="handleBtn(1)" v-if="!isQOrder">Add to Cart</span>
        <span @click="handleBtn(0)" :style="{'border-radius': isQOrder ? '5px' : '0px 5px 5px 0px'}">Buy Now</span>
      </div>
      <div class="btns" v-else>
        <span @click="handleBargain" :style="{'border-radius': isQOrder ? '5px' : '5px'}">Get</span>
      </div>
    </div>

    <!-- 商品详情图 -->
    <p class="hot-title">Product Image Gallery</p>
    <div class="detail-img">
      <img v-for="(item, index) in goodsInfoImg" :key="index" :src="item.url" alt="">
    </div>

    <!-- 快速下单 -->
    <transition name="van-fade">
      <Qlogin @success="loginSuccess" @close="isViewLogin = false" v-if="isViewLogin" />
    </transition>
  </section>
</template>

<script>
import GoodsSwipe   from '@/components/en/goods-swipe.vue'
import MDot         from '@/components/en/m-dot.vue'
import MScrollTop   from '@/components/en/m-scroll-top.vue'
import IndexNew     from '@/components/en/index-new.vue'
import Qlogin       from './qlogin.vue'

import { getDetailGoodsList, getGoodsInfo, getSku, getCommentTotal, checkLogin, } from '@/api/en/detail.js'
import { weshare }  from '@/api/zh/detail.js'
import { addCart } from '@/api/zh/cart.js'
import { favoriteGoods } from '@/api/en/favorite.js'
import { getBatch } from '@/api/public.js'
import { parseTime, _isIOS,  } from '@/untils/js/common.js'
// import wx from 'weixin-js-sdk' ;
export default {
  name:'Index',
  components:{ GoodsSwipe, MDot, IndexNew, MScrollTop, Qlogin },
  data(){
    return {
      tab:0,          // tabs选中的面板
      loading:false,
      num:2,
      list:2,
      cartAnimate:false,
      dotAnimate:false,
      scrollTop:false,
      skuData: [],
      isEmpty: false, // 当前选择的规格是否有库存
      isHidden: false,  // 当前选择的规格是否有该规格
      skuImg: '',
      goodsPrice: 0,
      goodsCostPrice: 0,
      selectSku: [],  // 选择的规格
      skuStock: '',
      skuId: '',
      number:1,
      remGoodsList: [],
      goodsInfo: {goods_info:''},
      goodsInfoImg: [],
      commentTotal: {},
      batch: {},
      isViewLogin: false,
      isQOrder: false,
      off: 0,
      shareBarcode: '',
      sharePrice: 0,
      sharePrePrice: 0,
      isNew: false,
      isPre: false,
      goodsId: '',
      proxyPrice: 0,
      agent_id: ''
    }
  },
  methods:{
    goodsBack() {
      // 返回
      if (sessionStorage.getItem('preLink')) {
        this.$router.push('/')
      }else if(this.$route.query.back) {
        this.$router.push(this.$route.query.back)
      } else {
        this.$router.go(-1)
      }
    },
    // 点击 减
    OnReduce(){
      this.number --
      if(this.number < 1){
        this.number = 1
      }
    },
    // 点击 加
    onAdd(){
      if(this.$route.query.pre && this.$route.query.pre == 1 && this.number >= 5) {
        this.$notify({ type: 'warning', message: 'Maximum 5 Items'})
      } else {
        if(this.skuStock.sku_stock && this.skuStock.sku_stock.stock > 0 && this.number < this.skuStock.sku_stock.stock) {
          this.number ++
        } else if((!this.skuStock.sku_stock || this.skuStock.sku_stock.stock <= 0) && this.number < 5) {
          this.number ++
        }
      }
    },
    goToStore() {
      // 跳转商家页面
      if(this.goodsInfo.merchant_id == 1 && this.$route.query.pre && this.$route.query.pre == 1) {
        this.$router.push(`/en/presale`)
      } else {
        this.$router.push(`/en/merchant/index/${this.goodsInfo.merchant_id}`)
      }
    },
    // 内容滑动事件
    handleScroll(e){
      this.$refs.bar.style.opacity = 1 - (e.target.scrollTop - 200)/200
      if(e.target.scrollTop >= 500){
        this.scrollTop = true
      }else{
        this.scrollTop = false
      }
    },
    // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      this.cartAnimate = true

      let timer = setTimeout( () => {
        this.cartAnimate = false
        this.dotAnimate = true 

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)

      },660)
    },
    // 切换 tab
    handleTab(tab){
      let left = 19.13333 + tab  * 50
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
      if(tab == 1){
        this.list = 5
      } else{
        this.list = 2
      }
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.skuModal  = true
      this.start_dom = e 
    },
    getDetailGoodsListHandle() {
      // 获取自定义为你推荐
      this.loading  = true
      getDetailGoodsList({page: 1, goods_id: this.$route.params.id}).then(res => {
        this.loading = false
        if(res) {
          this.remGoodsList = res.data.data
        }
      })
    },
    getGoodsInfoHandle(isPre = false) {
      // 获取商品详细信息
      let params = {
        pre: isPre ? 1 : this.$route.query.pre,
      }
      if(this.agent_id) {
        params.agent_id = this.agent_id
      }
      getGoodsInfo(this.$route.params.id, params).then(res => {
        if(res) {
          this.off = res.data.off;
          this.goodsInfo = res.data;
          this.goodsInfoImg = res.data.goods_info.goods_details_url
          this.wxShare();
          this.getSkuHandle();
          if((this.goodsInfo.activity_min_price *1).toFixed(0) != 0) {
            this.sharePrice = this.goodsInfo.activity_min_price
          } else {
            this.sharePrice = (this.goodsInfo.min_price *(this.goodsInfo.off == 0 ? 1 : this.goodsInfo.off/100)).toFixed(2)
          }
          this.costPrice = this.goodsInfo.min_price
          if (this.$route.query.pre == 1) {
            this.sharePrePrice = (this.goodsInfo.pre_info.pre_min_price *(this.goodsInfo.off == 0 ? 1 : this.goodsInfo.off/100)).toFixed(2)
          }
          if(this.goodsInfo.pre_info.pre_info == 1) {
            if(!this.isPre && this.$route.query.pre == 0) {
              this.getGoodsInfoHandle(true)
            }
            this.isPre = true
            this.isNew = true
          }

          //保存主图以便分享使用
          if(this.$store.state.isMiniProgram && this.goodsInfo.round_pic_url.length > 0) {
            window.wx.miniProgram.postMessage({
              data: {
                path: this.$route.fullPath,
                mate: {title: this.goodsInfo.goods_info.name, content: {description: ''}},
                shareImage: this.goodsInfo.round_pic_url[0]
              }
            })
          }
        }else {
          this.$router.push('/en/offshelf')
        }
      })
    },
    favoriteGoodsHandle() {
      let params = {
        status: this.goodsInfo.goods_collect == null ? 1 : 2
      }
      favoriteGoods(this.$route.params.id,params).then(res => {
        if(res) {
          
          if(this.goodsInfo.goods_collect == null) {
            this.$set(this.goodsInfo, 'goods_collect', 1)
          } else {
            this.$set(this.goodsInfo, 'goods_collect', null)
          }
          this.$notify({ type: 'success', message: res.msg})
        }
      })
    },
    // 点击变量事件
    handleActive(it, i, ind){
      this.number = 1
      it.val.forEach(e => {
        this.$set(e, 'active', false)
      })
      this.$set(i, 'active', true)

      if(this.skuData.attr.length > 1) {
        this.moreSkuJudge(i, ind)
      } else {
        this.selectSku = []
        this.selectSku.push(i.attr_title)
        this.skuData.sku.forEach(el => {
          if(el.attr_symbol_path == i.symbol) {
            this.skuImg = el.sku_pic_url
            this.goodsPrice = el.price
            this.proxyPrice = el.proxy_price
            this.goodsCostPrice = el.cost_price
            this.skuStock = el
            this.skuId = el.id
            
            this.shareBarcode = el.barcode
            this.sharePrice = el.price
            this.sharePrePrice = el.price
          }
        })

        if(i.emptyStock) {
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
      }
      // 强制刷新
      this.$forceUpdate()
    },
    moreSkuJudge(i, ind) {
      // 多规格判断库存
      // 匹配sku  获取价格和库存
      let sym_arr = [], active_arr = []
      let emptyArr = [], hiddenArr = []
      this.selectSku = []
      this.skuData.attr.forEach((el, index) => {
        if(index < ind) {
          el.val.forEach((ll, inn) => {
            sym_arr = []
            sym_arr.push(ll.symbol)
            sym_arr.push(i.symbol)
            this.judgeStock(sym_arr.join(','), index, inn)
          })
        }else if(index > ind) {
          el.val.forEach((ll, inn) => {
            sym_arr = []
            sym_arr.push(i.symbol)
            sym_arr.push(ll.symbol)
            this.judgeStock(sym_arr.join(','), index, inn)
          })
        }

        el.val.forEach(ll => {
          if(ll.active) {
            active_arr.push(ll.symbol)
            this.selectSku.push(ll.attr_title)
          }

          if(ll.emptyStock && ll.active) {
            emptyArr.push(true)
          }

          if(ll.hidden && ll.active) {
            hiddenArr.push(true)
          }
        })
      })
      // 判断是否有库存
      if(emptyArr.length == this.skuData.attr.length) {
        this.isEmpty = true
      }else{
        this.isEmpty = false
      }
      // 判断是否有该规格
      if(hiddenArr.length == this.skuData.attr.length) {
        this.isHidden = true
      }else{
        this.isHidden = false
      }

      this.skuData.sku.forEach(el => {
        if(el.attr_symbol_path == active_arr.join(',')) {
          this.skuStock = el.sku_stock
          this.skuId = el.id
          this.shareBarcode = el.barcode
          this.sharePrice = el.price
          this.sharePrePrice = el.price
        }
      })
      if ( this.skuStock == 'undefined' || !this.skuStock) {
           this.skuData.sku.every(el => {
            if(el.sku_stock.stock) {
               this.skuStock = el.sku_stock
              this.skuId = el.id
              this.shareBarcode = el.barcode
              this.sharePrice = el.price
              this.sharePrePrice = el.price
              return false
            }
         })
      }
    },
    judgeStock(str, index, ind) {
      // 判断库存是否剩余
      let isHidden = true
      this.skuData.attr[index].val[ind].emptyStock = false
      this.skuData.sku.forEach(el => {
        if(str == el.attr_symbol_path) {
          if(this.skuData.attr[index].val[ind].active) {
            this.skuImg = el.sku_pic_url
            this.goodsPrice = el.price
            this.proxyPrice = el.proxy_price
            this.goodsCostPrice = el.cost_price
          }

          isHidden = false
          if(!el.sku_stock || el.sku_stock.stock <= 0 || el.sku_stock.stock == null) {
            this.skuStock = null
            this.skuData.attr[index].val[ind].emptyStock = true
          }
        }
      })
      this.skuData.attr[index].val[ind].hidden = false
      if(isHidden) {
        this.skuData.attr[index].val[ind].hidden = true
      }
    },
    // 点击 BTN
    handleBtn(flag = 0){
      if(flag == 1) {
        this.addCart()
      } else {
        this.buyNow()
      }
    },
    // 加入购物车
    addCart() {
      console.log(this.skuStock)
      if(!this.skuStock.barcode) {
        this.$notify({ type: 'warning', message: 'No stock'})
        return false
      }

      let params = {
        goods_id: this.$route.params.id,
        barcode: this.skuStock.barcode,
        buy_number: this.number
      }
      addCart(params).then(res => {
        if(res) {
          this.handleCartSuccess()
          this.$store.commit('addCart', this.number)
        }
      })
    },
    // 立即购买
    buyNow() {
      let params = {
        buyId: this.skuId,
        buyNum: this.number
      }
      // 判断是否登录状态
      if(this.isQOrder) {
        checkLogin().then(res => {
          if(res) {
            if(res.status == 0) {
              this.isViewLogin = true
            } else {
              this.$router.push({ path: '/en/buynow', query: params  })
            }
          } else {
            this.isViewLogin = true
          }
        })
      } else {
        this.$router.push({ path: '/en/buynow', query: params  })
      }
    },
    handleBargain() {
      this.$router.push('/en/bargain')
    },
    getSkuHandle() {
       // 获取SKU
      let params = {}
      if(this.isPre) {
        params.pre = 1
      }
      getSku(this.$route.params.id,params).then(res => {
        this.initPre(res)
      }).finally(() => {
        this.isLoading = false
      })
    },
    initPre(res) {
      if(res) {
        this.skuData = res.data
        // 判断是否有条形码和数量
        if(this.barcode && this.num) {
          // 设置数量和选中
          this.number = this.num
          setTimeout(() => {
            let attr_symbol = []
            this.skuData.sku.forEach(el => {
              if(el.barcode == this.barcode) {
                attr_symbol = el.attr_symbol_path.split(',')
              }
            })
            this.skuData.attr.forEach((el, index) => {
              el.val.forEach(ll => {
                if(attr_symbol.indexOf(ll.symbol + '') > -1) {
                  this.handleActive(el, ll, index)
                }
              })
            })
          }, 200)
        } else {
          this.initSku()
        }
      }
    },
    initSku() {
      // 默认第一个规格图
      if(this.skuData.sku.length >= 1) {
        this.selectSku = []
        this.skuImg = this.skuData.sku[0].sku_pic_url
        this.goodsPrice = this.skuData.sku[0].price
        this.goodsCostPrice = this.skuData.sku[0].cost_price
        this.proxyPrice = this.skuData.sku[0].proxy_price
      }
      // 单多规格判断
      this.selectSku = []
      if(this.skuData.attr.length == 1) {
        // 单规格库存判断

        this.skuId  = this.skuData.sku[0].id
        this.skuStock = this.skuData.sku[0]

        // this.shareBarcode = this.skuData.sku[0].barcode
        // this.sharePrice = this.skuData.sku[0].price

        this.skuData.attr[0].val.forEach((el, index) => {
          el.emptyStock = false
          if(index == 0) {
            this.selectSku.push(el.attr_title)
            el.active = true
          }else{
            el.active = false
          }
          this.skuData.sku.forEach(ll => {
            if(el.symbol == ll.attr_symbol_path) {
              // this.skuStock = ll.sku_stock
              if(!ll.sku_stock || ll.sku_stock.stock <= 0 || ll.sku_stock.stock == null) {
                el.emptyStock = true
                if(index == 0) {
                  this.isEmpty = true
                }
              }
            }
            
          })
        })
        
      }else{
        // 多规格库存判断
        this.skuData.attr.forEach(el => {
          el.val.forEach(ll => {
            ll.active = false
            ll.emptyStock = false
            ll.hidden = false
          })
        })
        setTimeout(() => {
          this.skuData.attr.forEach((el, index) => {
            if(el.val.length > 0) {
              this.handleActive(el, el.val[0], index)
            }
          })
        }, 200)
      }
    },
    getCommentHandle() {
      // 获取评论
      getCommentTotal({goods_id: this.$route.params.id}).then(res => {
        if(res) {
          this.commentTotal = res.data
        }
      })
    },
    shareHandle() {
      // 分享
      let data = {
        id: this.goodsInfo.id,
        img: this.goodsInfo.round_pic_url[0] || '',
        title: this.goodsInfo.goods_info.name,
        price: this.sharePrice,
        costPrice: this.goodsCostPrice,
        prePrice: this.sharePrePrice,
        barcode: this.shareBarcode,
        url: 'https://m.moobyyoho.com' + this.$route.fullPath,
        merchant_id: this.goodsInfo.shops_info.merchant_id
      }
      if(this.goodsInfo.agent) {
        data.agent_id = this.goodsInfo.agent.id
      }
      localStorage.setItem('share', JSON.stringify(data))
      this.$router.push({path: '/en/share',query:{pre:this.$route.query.pre}})
    },
    // 微信分享
    wxShare(){
      // let jsUrl = '' // 验证签名所需URL
      let shareUrl = window.location.href // 分享链接的URL
      // if (_isWechat() && _isIOS()) {
      //   // 微信浏览器&&苹果设备, 取出记录的第一次访问的URL
      //   jsUrl = this.$store.state.jsUrl
      // } else {
      //   jsUrl = window.location.href; // 当前页面URL
      // }
      let params = {
        url: window.location.href,
      }
      weshare(params).then(res => {
        if(res) {
           window.wx.config({
              debug: false, // 开启调试模式
              appId: res.data.appId, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr:  res.data.nonceStr, // 必填，生成签名的随机串
              signature:  res.data.signature,// 必填，签名
              jsApiList: [
                // "onMenuShareTimeline",
                // "onMenuShareAppMessage",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ] 
          });

        
          const that = this;
           window.wx.ready(function() {
             window.wx.checkJsApi({
                jsApiList: ["showMenuItems"],
                success: function () {
                      window.wx.showMenuItems({
                        menuList: [

                            'menuItem:share:appMessage', //发送给朋友

                            'menuItem:share:timeline' //分享到朋友圈

                        ]

                    });
                }

             });
             window.wx.updateAppMessageShareData({
              title: "MOOBY YOHO", // 分享标题
              link:shareUrl, // 分享链接
              imgUrl: that.goodsInfo.round_pic_url[0].url || 'https://m.moobyyoho.com/load.png', // 分享图标
              desc: that.goodsInfo.goods_info.name || 'Wow! It’s Mooby Yoho! Check out this item...', // 分享描述
              success () {
                //  that.$notify({ type: 'success', message: 'Successful sharing' });
              },

            });
             window.wx.updateTimelineShareData({
              title: "MOOBY YOHO", // 分享标题
              link: shareUrl, // 分享链接
              imgUrl: that.goodsInfo.round_pic_url[0].url || 'https://m.moobyyoho.com/load.png', // 分享图标
              desc: that.goodsInfo.goods_info.name || 'Wow! It’s Mooby Yoho! Check out this item...', // 分享描述
              success () {
                //  that.$notify({ type: 'success', message: 'Successful sharing' });
              },
            });
          })
        }
      })
    },
    getBatchHandle() {
      // 获取批次时间
      getBatch().then(res => {
        if(res) {
          res.data.sta_time = parseTime(res.data.sta_time, '{y}-{m}-{d}')
          res.data.end_time = parseTime(res.data.end_time, '{y}-{m}-{d}')
          res.data.sta      = parseTime(res.data.sta_time, '{m}-{d}')
          res.data.end      = parseTime(res.data.end_time, '{m}-{d}')
          this.batch = res.data
        }
      })
    },
    loginSuccess() {
      // 登录成功
      this.isViewLogin = false
      this.buyNow()
    }
  },
  created() {
    this.goodsId = this.$route.params.id
    this.getDetailGoodsListHandle()
    // this.getSkuHandle()
    this.getCommentHandle()
    this.$store.dispatch('getCart')

    this.getBatchHandle()

    if(this.$route.query.qorder && this.$route.query.qorder == 1) {
      this.isQOrder = true
    } else {
      this.isQOrder = false
    }

    
    if(this.$route.query.pre && this.$route.query.pre == 1) {
      this.isNew = true
    }
  },
  mounted() {
    if(this.$route.query.agent_id) {
      this.agent_id = this.$route.query.agent_id
    }
    this.getGoodsInfoHandle();
  },
  watch: {
    '$route.params.id'(val) {
      if(val) {
        this.getDetailGoodsListHandle()
        this.getGoodsInfoHandle()
        // this.getSkuHandle()
        this.isNew = false
        this.getCommentHandle()
        this.$refs.scrollTop.onClick()
      }
    }
  },
  beforeRouteEnter: (to, from, next) => {
      /*
      *修复iOS版微信 默认是第一次进入应用的URL,这样就会导致错误
      * to.path  /详情/ID
      * location.hash #/xxxx
      */
     next()
      if (_isIOS() && to.path !== location.pathname) {
        location.assign(`${to.fullPath}`);
      } else {
        next();
      }
    
  }
}
</script>

<style lang="less" scoped>
@import './detail.less';

 .pre-box{background-color: #fff;
    .tips {
      margin-top:10px;
      padding: 10px 16px;
      font-size:14px;
      font-weight:400;
      color:rgba(102,102,102,1);display: flex;
    }
    p{font-size: 12px;color:#888;line-height: 20px;
      &:nth-of-type(1){width: 70px;margin-right:10px}
    }
  }
  .hot-title {
    font-size: 16px;font-weight: 500;color: #444;padding:10px 16px;margin-top:10px;
  }
  .mar-tb {
    margin: 10px 0 20px;
  }

/deep/.vjs-tech{object-fit: cover;}
/deep/.vjs-poster{background-size: cover;}
/deep/ .van-rate {
        .van-icon {
          width: 15px;
          height: 15px;
          color: rgb(240, 60, 24);
          &::before {
            width: 100%;
            height: 100%;
            font-size: 15px;
          }
        }
      }
    .detail-f{margin-left:10px;font-size:12px;color:#888}
</style>