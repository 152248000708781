<template>
    <div class="qlogin-all">
        <div class="qlogin-bg" @click="$emit('close', false)"></div>
        <div class="qlogin-main">
            <div class="qlogin-top">Authentication</div>
            <div class="input-box">
                <p>Phone Number</p>
                <div class="code-box" :class="{'active': focus == 1}">
                    <input type="number" maxlength="11" placeholder="Please input mobile phone number" @focus="focus = 1" v-model="phone">
                </div>
            </div>
            <div class="input-box">
                <p>Code</p>
                <div class="code-box" :class="{'active': focus == 2}">
                    <input type="text" placeholder="Please enter the verification code" @focus="focus = 2" v-model="code">
                    <span @click="getCodeHandle">
                        {{second + (typeof(second) == 'number' ? ' s' : '')}}
                        <van-loading color="#1989fa" size="20px" v-if="second == ''" style="margin:0" />
                    </span>
                </div>
            </div>
            <div class="submit-search" @click='codeLoginHandle'>Confirm</div>
        </div>
    </div>
</template>

<script>
import { phone_code, phone_login } from '@/api/zh/login'
import { getCoupon } from "@/api/en/night_market.js";
export default {
    data() {
        return {
            focus: false,
            second: 'Send',
            timer: null,
            phone: '',
            code: ''
        }
    },
    methods: {
        getCodeHandle() {
            // 获取验证码
            if(this.phone == '' || !/^[0-9]*$/.test(this.phone)) {
                this.$notify({ type: 'warning', duration: 2000, message: 'Please input the correct mobile phone number' })
                return false
            }
            let params = { 
                phone    : this.phone,
                area_code: this.phone.length == 11 ? 86: 1,
                model    : 'login'
            }
            this.second = ''
            phone_code(params).then(res => {
                if(res) {
                    this.second = 60
                    this.timer = setInterval(() => {
                        this.second--
                        if(this.second <= 0) {
                            this.second = 'Send'
                            clearInterval(this.timer)
                        }
                    }, 1000)
                } else {
                    this.$notify({ type: 'warning', duration: 2000, message: res.msg })
                }
            }).finally(() => {
                this.$store.commit('unload')
            })
        },
        codeLoginHandle() {
            let params = { 
                phone    : this.phone,
                area_code: this.phone.length == 11 ? 86: 1,
                code     : this.code
            }
            phone_login(params).then(res => {
                if(res) {
                    localStorage.setItem('token', res.access_token)
                    localStorage.setItem('token2', res.access_token)
                    getCoupon().then(res => {  
                        if(res){
                            console.log(res)
                        }
                    })
                    this.$emit('success', true)
                }else{
                    this.$notify({ type: 'warning', duration: 2000, message: res.msg })
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.qlogin-all{position: fixed;z-index: 2000;width:100%;height:100%;left:0;top:0;
    .qlogin-bg{position: absolute;top:0;left:0;width:100%;height:100%;background-color: #000;opacity: .3;}
    .qlogin-main{position: absolute;left:0;bottom: 0;width:100%;height:320px;background-color: #fff;border-top:1px solid #f1f1f1;
    border-radius: 16px 16px 0 0;
        .qlogin-top{width:100%;height:38px;line-height: 38px;text-align: center;font-size: 14px;color:#444;box-shadow: 0 2px 4px #f1f1f1;
        margin-bottom:20px}
        .input-box{padding:0 16px;
            >p{font-size: 14px;line-height: 14px;color: #000000;margin-bottom:10px}
            .code-box{background: #FFFFFF;border: 1px solid #E6E6E6;border-radius: 6px;margin-bottom:25px;display: flex;
            box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.203611);
                >span{font-size: 14px;line-height: 14px;color: #2D77E0;height:22px;display: block;line-height: 22px;margin-top:9px;
                border-left:1px solid #E6E6E6;padding:0 10px;word-break: keep-all;}
                
            }
            input{border:none;outline: none;height:40px;background-color: transparent;flex:1;display: block;min-width:0;padding:0 10px;
            font-size: 14px;}
            .active{box-shadow: 0px 0px 0px 2px rgba(50, 133, 250, 0.151);border-color: #3284FA;}
        }
        .submit-search{background: linear-gradient(295.9deg, #F03C18 12.2%, #F06448 82.1%);border-radius: 25px;width:340px;height:38px;
        text-align: center;color:#fff;line-height: 38px;margin:20px auto 0 auto;font-size: 14px;}
    }
}
</style>